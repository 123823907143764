import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import RedirecionaPaginas from './components/RedirecionaPaginas';
import HomePage from './components/HomePage';

const App = () => {
  return (
    <Router>
      <Routes>
        {/* <Route path="/" element={<HomePage />} /> */}
        <Route exact  path="*" element={<RedirecionaPaginas />} /> 
      </Routes>
    </Router>
  );
};

export default App;
