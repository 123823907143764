import React from 'react';

import { BrowserRouter as Router } from 'react-router-dom';

import Routes from './routes';

function App() {
  return (
      <Routes/>
  );
}

export default App;