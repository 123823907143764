import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const RedirecionaPaginas = (props) => {
  const subpagina = useParams();
  const paginas = subpagina["*"].split('/');
  const navigate  = useNavigate ();

  //Lógica para redirecionar com base no conteúdo de subpaginasArray
  const redirecionarParaComponente = () => {
    console.log(paginas)
      let urlPaginas = paginas
      let primeira = urlPaginas[0]
      console.log(urlPaginas.length)
      if(urlPaginas.length > 1){
        navigate(`/${primeira}?subPaginas=${paginas.join('&')}`)
      }
  };

  return (
    <div>
      {redirecionarParaComponente()}
      TESTE
    </div>
  );
};

export default RedirecionaPaginas;
